.App {
  text-align: center;
}
.App-header {
  background-color: #2d2d2d;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.App-header footer {
  position: static;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #080808;
  display: flex;
  flex-direction: column;
  font-size: calc(8px + 1vmin);
  line-height: 1.5;
  width: 100%
}
.App-social-icon {
  min-height: 5vh;
  display: inline;
  flex-direction: row;
}

.App-social-icon-i {
  margin-right: 10px;
}

h1 {
  color: white;
  font-size: calc(8px + 3vmin);
}
h2 {
  color: white;
  font-size: calc(5px + 2vmin);
}
h3 {
  color: white;
  font-size: calc(3px + 2vmin);
}
a:link {
  color: white;
  text-decoration: none;
}

/* visited link */
a:visited {
  color: white;
  text-decoration: none;
}

/* mouse over link */
a:hover {
  color: white;
  text-decoration: none;
}

/* selected link */
a:active {
  color: white;
  text-decoration: none;
}

.App-link {
  color: #61dafb;
}